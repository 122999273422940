export default function getClassName(count, rating) {
    const rating_round = (Math.round(rating * 2) / 2).toFixed(1);
    if (count <= rating_round) {
        return 'icon-star';
    } else if ((count-rating_round) < 1) {
        return 'icon-star-half-alt';
    } else {
        return 'icon-star-empty';
    }
}
