import React, {useState} from 'react';
import "./ratingModal.scss";
import {Text} from "../../elements/Text/text";
import {Title} from "../../elements/Title/title";
import {Input} from "../../modules/Form/Input/input";
import {Textarea} from "../../modules/Form/Textarea/textarea";
import {Button} from "../../elements/Button/button";

export const RatingModal = props => {

    const textStatic = props.textStatic;

    const [data, setData] = useState({
        name: '',
        text: ''
    });

    const handleInputChange = (event) => {
        setData({
            ...data,
            [event.target.name] : event.target.value
        })
    };

    return (
        <div className="modal m-rating-modal">
            <div className={"modal-dialog"}>
                <div className={"modal-close"} onClick={props.handler} aria-hidden="true">x</div>
                <Title content={textStatic.rating_modal_title} />
                <Input label={textStatic.rating_modal_name_label} type={"text"} onChangeFunction={handleInputChange} name="name" />
                <Textarea label={textStatic.rating_modal_text_label} onChangeFunction={handleInputChange} name="text" />
                <Text size13 content={textStatic.rating_modal_footer_text} />

                <div className={"m-rating-modal--block-btns"}>
                    <Button small content={textStatic.rating_modal_close_btn} onClickFunction={props.handler} />
                    <Button primary content={textStatic.rating_modal_send_btn} onClickFunction={()=> props.onSendForm(data.name, data.text)}/>
                </div>
            </div>
        </div>
    );
};