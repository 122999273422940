import React from "react";
import "./list.scss";
import classNames from "classnames";
import {LinkBtn} from "../../../components/elements/LinkBtn/linkBtn";

const List = props => {

    const classnames = classNames(`m-list`, {
        "m-list--col-2": props.col && props.col===2,
        "m-list--col-3": props.col && props.col===3,

        //colSm
        "m-list--col-sm-1": props.colSm && props.colSm===1,
    });

    return (
        <ul className={classnames}>
            {Object.keys(props.data).map((key) => {
                return(
                    <li>
                        <LinkBtn middle to={props.data[key].url} content={props.data[key].text}/>
                    </li>
                )}
            )}
        </ul>
    );
};

export default List;