import React from 'react';
import "./input.scss";

export const Input = props => {
    return (
        <div className="m-input">
            <label htmlFor="">{props.label}</label>
            <input
                name={props.name}
                type={props.type}
                value={props.value}
                onChange={props.onChangeFunction}
            />
        </div>
    );
};