import React from 'react';
import "./textarea.scss";

export const Textarea = props => {
    return (
        <div className="m-textarea">
            <label htmlFor="">{props.label}</label>
            <textarea
                name={props.name}
                onChange={props.onChangeFunction}
                id=""
                cols="30"
                rows="10">
                {props.value}
            </textarea>
        </div>
    );
};